import { z } from 'zod';

export type Attribute = z.infer<typeof Attribute>;
export const Attribute = z.object({
    name: z.string(),
    inputType: z.string(),
    defaultValue: z.string(),
    values: z.array(z.string()),
    id: z.number(),
});

export type Label = z.infer<typeof Label>;
export const Label = z.object({
    name: z.string(),
    type: z.string(),
    color: z.string(),
    id: z.number(),
    attributes: z.array(Attribute),
});

export type Job = z.infer<typeof Job>;
/** Describes the structure of a Job in state.annotation.job`. */
export const Job = z.object({
    labels: z.array(Label),
});
