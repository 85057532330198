import { Coordinates, FOV } from './geolocation';
import { LidarRelatedImage } from './lidar';
import { TypedAttributeDefinition } from './typed-attribute';

// These match the types defined in
// https://github.com/roadgnar/cyvl-annotation-format-utils/blob/b1952e2505019b62117fe64ad456d46856b17268/cyvl_annotation_format_utils/cyvl_data_annotations/typed_attribute
export const ALL_TYPED_ATTRIBUTES: Record<string, TypedAttributeDefinition | undefined> = {
    [FOV.Name]: FOV,
    [Coordinates.Name]: Coordinates,
    [LidarRelatedImage.Name]: LidarRelatedImage,
};
