import { z } from 'zod';
import { Label } from './cvat';
import { TypedAttributeValue, createTypedAttribute } from './typed-attribute';
import { findTypedAttribute } from './util';

export type Coordinates = TypedAttributeValue<typeof Coordinates>;
export const Coordinates = createTypedAttribute(
    'Coordinates',
    z.object({ lat: z.number(), lon: z.number() }),
    ({ lat, lon }) => formatLatLng(lat, lon),
);

export type FOV = TypedAttributeValue<typeof FOV>;
export const FOV = createTypedAttribute(
    'FOV',
    z.object({
        bearing: z.number(),
        fov: z.number(),
        near: z.number(),
        far: z.number(),
    }),
    ({ bearing }) => formatBearing(bearing),
);

const formatLatLng = (lat: number, lon: number): string =>
    `${Math.abs(lat).toFixed(2)}° ${lat > 0 ? 'N' : 'S'}, ${Math.abs(lon).toFixed(2)}° ${lon > 0 ? 'E' : 'W'}`;

const formatBearing = (raw: number): string => {
    let bearing = raw % 360;
    let dir = 'E';
    if (raw < 0) bearing += 360;
    if (bearing > 45 && bearing <= 135) dir = 'N';
    if (bearing > 135 && bearing <= 225) dir = 'W';
    if (bearing > 225 && bearing <= 315) dir = 'S';

    return `${bearing.toFixed(0)}° ${dir}`;
};

export type MetadataLabel = z.infer<typeof MetadataLabel>;
export const MetadataLabel = Label.extend({
    name: z.literal('camera'),
    type: z.literal('tag'),
}).transform((label, ctx) => {
    const location = findTypedAttribute(label.attributes, 'location', Coordinates);
    const fov = findTypedAttribute(label.attributes, 'fov', FOV);

    if (!location.success || !fov.success) {
        if (!location.success) location.error.issues.forEach(ctx.addIssue);
        if (!fov.success) fov.error.issues.forEach(ctx.addIssue);
        return z.NEVER;
    }

    return { ...label, types: { location: location.data, fov: fov.data } };
});

export type GeolocatedLabel = z.infer<typeof GeolocatedLabel>;
export const GeolocatedLabel = Label.transform((label, ctx) => {
    const location = findTypedAttribute(label.attributes, 'location', Coordinates);

    if (!location.success) {
        location.error.issues.forEach(ctx.addIssue);
        return z.NEVER;
    }
    return { ...label, types: { location: location.data } };
});
