import { ObjectState } from 'cvat-core-wrapper';
import { AnyAction, Dispatch } from 'redux';
import { z } from 'zod';
import { Label, LabelState, TypedAttributeMap, TypedLabel, getLabelStates } from './model';

export type ParseableTask<T extends CyvlTask> = {
    parse(jobRaw: unknown): T;
    safeParse(jobRaw: unknown): z.SafeParseReturnType<any, T>;
};

export abstract class CyvlTask {
    constructor(readonly labels: Label[]) {}

    protected states: ObjectState[] = [];
    protected activatedStateID: number | null = null;
    protected dispatch!: Dispatch<AnyAction>;

    /** Called each render, observing redux state. */
    onRender({
        states,
        activatedStateID,
        dispatch,
    }: {
        states: ObjectState[];
        activatedStateID: number | null;
        dispatch: Dispatch<AnyAction>;
    }) {
        if (this.dispatch !== dispatch) this.dispatch = dispatch;

        let needsUpdate = false;
        if (this.states !== states) {
            needsUpdate = true;
            this.states = states;
        }
        if (this.activatedStateID !== activatedStateID) {
            needsUpdate = true;
            this.activatedStateID = activatedStateID;
        }

        if (needsUpdate) {
            this.onUpdate();
        }
    }

    protected abstract onUpdate(): void;

    getLabelStates = <R extends TypedAttributeMap>(label: TypedLabel<R>): LabelState<R>[] => {
        return getLabelStates(this.states, label);
    };
}
