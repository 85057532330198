import { useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import * as turf from '@turf/turf';

export const useMetersPerPixel = () => {
    const [metersPerPixel, setMetersPerPixel] = useState(1);
    const map = useMap().current;

    useEffect(() => {
        if (!map) return () => {};

        const cb = () => {
            const center = map.getCenter();

            const sampleLengthMeters = 1;
            const c1 = turf.point([center.lng, center.lat]);
            const c2 = turf.transformTranslate(c1, sampleLengthMeters, 0, { units: 'meters' });
            const p1 = map.project(c1.geometry.coordinates as [number, number]);
            const p2 = map.project(c2.geometry.coordinates as [number, number]);

            const sampleLengthPx = p1.dist(p2);

            setMetersPerPixel(sampleLengthMeters / sampleLengthPx);
        };
        cb();
        map.on('move', cb);
        return () => map.off('move', cb);
    }, [map]);

    return metersPerPixel;
};
