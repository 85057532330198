import React, { ComponentType, FC, RefObject, useEffect, useRef, useState } from 'react';
import {
    MapProvider,
    MapRef,
    Map as ReactMap,
    Marker,
    useMap,
    MarkerProps,
    ScaleControl,
    NavigationControl,
} from 'react-map-gl';
import { Anchor, Marker as MapboxMarker } from 'mapbox-gl';
import useResizeObserver from 'beautiful-react-hooks/useResizeObserver';
import { CarIcon, CircleIcon, CrosshairIcon, MapPinIcon } from 'icons';
import Icon from '@ant-design/icons';
import { get } from 'lodash';
import { number } from 'prop-types';
import { Coordinates, FOV } from './model';
import { useGeolocationTask } from './hooks';
import { FOVMarker } from './fov-marker';
import { Camera, GeolocationAsset, GeolocationTask } from './geolocation-task';
import { ACTIVATED_OBJECT_COLOR, FOV_FILL_COLOR, FOV_STROKE_COLOR } from './consts';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoicm9hZGduYXIiLCJhIjoiY2tsb3BjanA4MHY2eDJ5cXBjdXNndDJyOCJ9.8gLZYamV12YMA5sOmOALCg';

const GEOLOCATION_MAP_ID = 'geolocation-map';

export type GeoItem = Coordinates & {
    label: string;
    id: number;
    color: string;
};

const DEFAULT_FOV: FOV = {
    fov: 74,
    near: 6,
    far: 15,
    bearing: 30,
};

const useGeolocationMap = (): MapRef | undefined => useMap()[GEOLOCATION_MAP_ID];

export const MapComponent: FC = () => (
    <MapProvider>
        <Map />
    </MapProvider>
);

const useCenterFrame = (task: GeolocationTask, map?: MapRef) => {
    const { camera, bounds } = task;
    const [centeredFrame, setCenteredFrame] = useState(NaN);
    useEffect(() => {
        if (map && camera && camera.location && camera.frame !== centeredFrame) {
            const bearing = camera.fov?.bearing === undefined ? 0 : 90 - camera.fov.bearing;
            map.fitBounds(bounds, {
                animate: false,
                bearing,
                pitch: 0,
                maxZoom: 21,
                padding: 20,
            });
            setCenteredFrame(camera.frame);
        }
    }, [bounds, camera, centeredFrame, map]);
};

const useAutoselectAsset = (task: GeolocationTask) => {
    const { assets, camera } = task;
    const [autoSelectedFrame, setAutoSelectedFrame] = useState(NaN);
    useEffect(() => {
        if (camera && camera.frame !== autoSelectedFrame) {
            assets[0]?.onSelect();
            setAutoSelectedFrame(camera.frame);
        }
    }, [assets, autoSelectedFrame, camera]);
};

const useLockCamera = (camera?: Camera) => {
    useEffect(() => {
        if (camera && !camera.isLocked) camera.lock();
    }, [camera]);
};

const useResizeContainer = (containerRef: RefObject<HTMLDivElement>, map?: MapRef) => {
    const size = useResizeObserver(containerRef);
    useEffect(() => {
        if (size?.width && size.height) map?.resize();
    }, [map, size?.height, size?.width]);
};

const useColors = () => {
    useEffect(() => {
        document.documentElement.style.setProperty('--activated-shape-color', ACTIVATED_OBJECT_COLOR);
    }, []);
};

export const Map = () => {
    const task = useGeolocationTask();
    const { assets, camera, bounds } = task;
    const map = useGeolocationMap();
    const containerRef = useRef(null);

    useColors();
    useResizeContainer(containerRef, map);
    useLockCamera(camera);
    useAutoselectAsset(task);
    useCenterFrame(task, map);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
            <ReactMap
                keyboard={false}
                onLoad={(e) => {
                    (window as any).mapbox = e.target;
                }}
                id={GEOLOCATION_MAP_ID}
                mapboxAccessToken={MAPBOX_TOKEN}
                maxPitch={60}
                mapStyle='mapbox://styles/roadgnar/clfwqfhu0000u01mr2x28rxnb'
                reuseMaps
                initialViewState={task.defaultViewState}
            >
                <ScaleControl unit='imperial' position='bottom-right' maxWidth={300} />
                <NavigationControl />
                {camera && <CameraMarker camera={camera} />}
                {assets.map((a) => (
                    <AssetMarker key={a.id} {...a} />
                ))}
            </ReactMap>
        </div>
    );
};

const CameraMarker: FC<{ camera: Camera }> = ({ camera: { location, color, fov } }) => {
    if (!location) return null;

    return (
        <>
            {fov && (
                <FOVMarker
                    {...location}
                    {...fov}
                    fillColor={FOV_FILL_COLOR}
                    strokeColor={FOV_STROKE_COLOR}
                    strokeWidth={0.3}
                />
            )}
            <GeolocationMarker
                markerProps={{
                    anchor: 'center',
                    rotation: fov?.bearing !== undefined ? 90 - fov.bearing : 0,
                    draggable: false,
                }}
                icon={CarIcon}
                lat={location.lat}
                lon={location.lon}
                color={color}
            />
        </>
    );
};

const AssetMarker: FC<GeolocationAsset> = ({ location, color, active, onSelect, update }) => {
    const [hovered, setHovered] = useState(false);

    if (!location) return null;

    return (
        <GeolocationMarker
            lat={location.lat}
            lon={location.lon}
            icon={active || hovered ? CrosshairIcon : CircleIcon}
            iconStyle={{
                width: '30px',
                padding: active || hovered ? '0px' : '10px',
                cursor: hovered && !active ? 'pointer' : undefined,
            }}
            markerProps={{ anchor: 'center', rotationAlignment: 'viewport' }}
            color={color}
            active={active}
            onMouseDown={onSelect}
            onFocus={() => setHovered(true)}
            onBlur={() => setHovered(false)}
            onChange={(location) => update(location)}
        />
    );
};
/**
 * A marker that shows the location of the attribute.
 */
const GeolocationMarker: FC<{
    lat: number;
    lon: number;
    color: string;
    icon?: ComponentType;
    iconStyle?: React.CSSProperties;
    markerProps?: Partial<MarkerProps>;
    active?: boolean;
    onChange?: (a: Coordinates) => void;
    onMouseDown?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
}> = ({ lat, lon, color, active, onChange, onFocus, onBlur, onMouseDown, ...props }) => {
    const ref = useRef<MapboxMarker>(null);
    const callbacksRef = useRef({ onFocus, onBlur, onMouseDown });
    callbacksRef.current = { onFocus, onBlur, onMouseDown };

    useEffect(() => {
        if (!ref.current || !callbacksRef.current) return () => {};
        let isFocused = false;

        const enter = () => {
            if (!isFocused) callbacksRef.current.onFocus?.();
            isFocused = true;
        };

        const leave = () => {
            if (isFocused) callbacksRef.current.onBlur?.();
            isFocused = false;
        };

        const down = () => callbacksRef.current.onMouseDown?.();

        const el = ref.current.getElement();
        el.addEventListener('mouseover', enter);
        el.addEventListener('mouseleave', leave);
        el.addEventListener('mousedown', down);

        return () => {
            el.removeEventListener('mouseover', enter);
            el.removeEventListener('mouseleave', leave);
            el.removeEventListener('mousedown', down);
        };
    }, []);

    const [dragState, setDragState] = useState<{ lat: number; lon: number } | null>(null);

    return (
        <Marker
            longitude={dragState?.lon ?? lon}
            latitude={dragState?.lat ?? lat}
            draggable
            ref={ref}
            rotationAlignment='map'
            onDrag={(e) => setDragState({ lat: e.lngLat.lat, lon: e.lngLat.lng })}
            onDragEnd={(e) => {
                onChange?.({ lat: e.lngLat.lat, lon: e.lngLat.lng });
                setDragState(null);
            }}
            {...props.markerProps}
        >
            <Icon
                component={props.icon ?? CircleIcon}
                style={{
                    color: active ? ACTIVATED_OBJECT_COLOR : color,
                    display: 'block',
                    width: '30px',
                    ...props.iconStyle,
                }}
            />
        </Marker>
    );
};
