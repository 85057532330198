import React from 'react';
import Button from 'antd/lib/button';
import { useLidarAnnotationTask } from './hooks';

export const FOVToggle = ({ name }: any) => {
    const image = useLidarAnnotationTask()?.getRelatedImage(name);
    if (!image) return null;
    return (
        <div className='cvat-context-image-extra' style={{ backgroundColor: image.color }}>
            <Button
                onClick={() => image.setVisibility(!image.isVisible)}
                type='text'
                style={{ width: '100%', height: '100%' }}
            >
                {image.isVisible ? 'Hide' : 'Show'} FOV
            </Button>
        </div>
    );
};
