import { Marker } from 'react-map-gl';
import React from 'react';
import { useMetersPerPixel } from './hooks';
import { FOV as FOVAttributeValue } from './model';
import { AnnulusSegment } from './svg-elements';

type Props = FOVAttributeValue & {
    lat: number;
    lon: number;
    fillColor: string;
    strokeColor: string;
    strokeWidth: number;
};

export const FOVMarker = ({ lat, lon, bearing, far, fov, near, fillColor, strokeColor, strokeWidth }: Props) => {
    const metersPerPixel = useMetersPerPixel();
    const fovHeightMeters = far,
        fovMinHeightPx = 15,
        fovHeightPx = Math.max(fovHeightMeters / metersPerPixel, fovMinHeightPx),
        fovWidthPx = fovHeightPx * 2;
    return (
        <Marker
            latitude={lat}
            longitude={lon}
            rotation={90 - bearing}
            rotationAlignment='map'
            pitchAlignment='map'
            anchor='center'
            offset={[0, -fovHeightPx / 2]}
        >
            <AnnulusSegment
                width={fovWidthPx}
                height={fovHeightPx}
                fillColor={fillColor}
                strokeColor={strokeColor}
                strokeWidth={strokeWidth}
                fov={fov}
                near={near}
                far={far}
            />
        </Marker>
    );
};

