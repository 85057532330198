import { z } from 'zod';
import { Label } from './cvat';
import { FOV } from './geolocation';
import { TypedAttributeValue, TypedAttributeWithSchema, createTypedAttribute } from './typed-attribute';
import { findTypedAttribute } from './util';

export const MAX_LIDAR_RELATED_IMAGES = 4;
export const relatedImageAttributeNames = Array.from({ length: MAX_LIDAR_RELATED_IMAGES }, (_, i) => `image_${i}`);
export const LIDAR_METADATA_LABEL_NAME = 'related_imagery' as const;

type RelatedImageType = TypedAttributeWithSchema<typeof LidarRelatedImage>;
type LidarMetadataAttributes = {
    image_0: RelatedImageType;
    image_1: RelatedImageType;
    image_2: RelatedImageType;
    image_3: RelatedImageType;
};

export type LidarRelatedImage = TypedAttributeValue<typeof LidarRelatedImage>;
export const LidarRelatedImage = createTypedAttribute(
    'LidarRelatedImage',
    z.object({
        url: z.string().url(),
        x: z.number(),
        y: z.number(),
        fov: FOV.Value,
    }),
    ({ url }) => new URL(url).pathname.split('/').pop() ?? 'Image',
);

export type LidarMetadataLabel = z.infer<typeof LidarMetadataLabel>;
export const LidarMetadataLabel = Label.extend({
    name: z.literal(LIDAR_METADATA_LABEL_NAME),
    type: z.literal('tag'),
}).transform((label, ctx) => {
    const relatedImages = relatedImageAttributeNames.flatMap((name) => {
        const attribute = findTypedAttribute(label.attributes, name, LidarRelatedImage);
        if (attribute.success) return [[name, attribute.data]];
        attribute.error.issues.forEach(ctx.addIssue);
        return [];
    });

    return { ...label, types: Object.fromEntries(relatedImages) as LidarMetadataAttributes };
});
