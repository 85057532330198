import { ObjectState } from 'cvat-core-wrapper';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
import { CyvlTask, ParseableTask } from '../cyvl-task';

export const createUseTask = (Task: Omit<typeof CyvlTask, 'new'>) => (): CyvlTask => {
    const parser = Task as unknown as ParseableTask<CyvlTask>;

    const job = useSelector((state: CombinedState) => state.annotation.job);
    const task = useRef<CyvlTask>();
    if (!task.current) task.current = parser.parse(job);

    const states = useSelector((state: CombinedState) => state.annotation.annotations.states as ObjectState[]);
    const activatedStateID = useSelector((state: CombinedState) => state.annotation.annotations.activatedStateID);
    const dispatch = useDispatch();

    task.current.onRender({
        states,
        activatedStateID,
        dispatch,
    });

    return task.current;
};
