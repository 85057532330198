import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
import { useEffect, useState } from 'react';
import { LidarImageryPlugin } from '../lidar-imagery-plugin';

export const useLidarAnnotationTask = () => {
    const [v, setV] = useState(0);
    const lidarPlugin = useSelector((state: CombinedState) => state.plugins.apis.job) as LidarImageryPlugin | undefined;
    const task = lidarPlugin?.task;

    useEffect(() => {
        if (!task) return () => {};
        const rerender = () => {
            setV((v) => v + 1);
        };
        task.addChangeListener(rerender);
        return () => task.removeChangeListener(rerender);
    });

    return lidarPlugin?.task;
};
