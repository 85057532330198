import React, { CSSProperties, FC, SVGProps, useRef } from 'react';

export const AnnulusSegment: FC<
    {
        fillColor?: string;
        strokeColor?: string;
        strokeWidth?: number;
        fov: number;
        near: number;
        far: number;
        originX?: number;
        originY?: number;
        originRotation?: number;
    } & SVGProps<SVGSVGElement>
> = ({ fov, near, far, fillColor, strokeColor, strokeWidth, style, originX, originY, originRotation, ...props }) => {
    const suffix = useRef(Math.random().toString(36).substring(2, 15));
    const ids = {
        fov: `fov-${suffix.current}`,
        insideFov: `insideFov-${suffix.current}`,
    };
    const width = far * 2;
    const height = far;
    const centerX = width / 2;
    const centerY = far;

    // Calculate the start and end points for the annulus segment
    const theta = (fov / 2) * (Math.PI / 180); // Convert half of the fov to radians
    const x1 = centerX - near * Math.sin(theta);
    const y1 = centerY - near * Math.cos(theta);
    const x2 = centerX - far * Math.sin(theta);
    const y2 = centerY - far * Math.cos(theta);
    const x3 = centerX + far * Math.sin(theta);
    const y3 = centerY - far * Math.cos(theta);
    const x4 = centerX + near * Math.sin(theta);
    const y4 = centerY - near * Math.cos(theta);

    const baseStyle: CSSProperties = {
        overflow: 'visible',
    };
    let transform: string | undefined;
    if (originX !== undefined && originY !== undefined) {
        Object.assign(baseStyle, {
            position: 'absolute',
            left: originX,
            top: originY,
            marginLeft: -width * 0.5,
            marginTop: -height,
        });
    }
    if (originRotation !== undefined) {
        const ccwRightRotation = 90 - originRotation;
        transform = `rotate(${ccwRightRotation} ${width * 0.5} ${height})`;
    }

    return (
        <svg
            width={width}
            height={height}
            display='block'
            viewBox={`0 0 ${width} ${height}`}
            style={{ ...baseStyle, ...style }}
            {...props}
        >
            <defs>
                <path
                    id={ids.fov}
                    d={`M ${x1} ${y1}
                        L ${x2} ${y2}
                        A ${far} ${far} 0 ${fov > 180 ? 1 : 0} 1 ${x3} ${y3}
                        L ${x4} ${y4}
                        A ${near} ${near} 0 ${fov > 180 ? 1 : 0} 0 ${x1} ${y1} Z`}
                    transform={transform}
                />
                <clipPath id={ids.insideFov}>
                    <use xlinkHref={`#${ids.fov}`} />
                </clipPath>
            </defs>

            <use
                xlinkHref={`#${ids.fov}`}
                fill={fillColor}
                stroke={strokeColor}
                // The stroke width is doubled because the stroke is centered on
                // the path and we clip half of it away
                strokeWidth={strokeWidth === undefined ? undefined : strokeWidth * 2}
                clipPath={`url(#${ids.insideFov})`}
            />
        </svg>
    );
};

export const Dot: FC<
    {
        fillColor?: string;
        strokeColor?: string;
        strokeWidth?: number;
        radius: number;
        centerX?: number;
        centerY?: number;
    } & SVGProps<SVGSVGElement>
> = ({ fillColor, strokeColor, strokeWidth, radius, centerX, centerY, style, ...props }) => {
    const size = radius * 2;
    const baseStyle: CSSProperties = {
        overflow: 'visible',
    };
    if (centerX !== undefined && centerY !== undefined) {
        Object.assign(baseStyle, {
            position: 'absolute',
            left: centerX - radius,
            top: centerY - radius,
        });
    }
    return (
        <svg
            width={size}
            height={size}
            display='block'
            viewBox={`0 0 ${size} ${size}`}
            style={{ ...baseStyle, ...style }}
            {...props}
        >
            <circle
                cx={radius}
                cy={radius}
                r={radius}
                fill={fillColor}
                stroke={strokeColor}
                strokeWidth={strokeWidth}
            />
        </svg>
    );
};
