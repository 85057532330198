import { z } from 'zod';
import { ObjectState } from 'cvat-core-wrapper';
import { Attribute } from './cvat';
import type { TypedAttributeDefinition, TypedAttributeMap, TypedAttributeValues, TypedLabel } from './typed-attribute';

export const parseJSONObjectOrNull = (raw: unknown) => {
    if (typeof raw !== 'string' || raw.length === 0) return null;
    try {
        const parsed = JSON.parse(raw);
        if (typeof parsed !== 'object') return null;
        return parsed;
    } catch (e) {
        return null;
    }
};

export const findTypedAttribute = <T extends TypedAttributeDefinition>(
    attributes: Attribute[],
    name: string,
    Type: T,
) =>
    mapFirst(attributes, (attr) => {
        const parsed = Type.Attribute.safeParse(attr);
        if (parsed.success && parsed.data.name.name === name) {
            return { success: true, data: { ...parsed.data, schema: Type.Value as T['Value'] } };
        }
        const error = new z.ZodError([]);
        error.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Attribute ${name}: ${Type.Name} is required`,
            path: [name],
        });
        return { success: false, error };
    });

/**
 * Returns the first successful result of applying `fn` to each element of `inputs`, or a merged zod error if all failed.
 */
export const mapFirst = <I, O>(inputs: I[], fn: (i: I) => z.SafeParseReturnType<I, O>): z.SafeParseReturnType<I, O> => {
    const error = new z.ZodError([]);
    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        const result = fn(input);
        if (result.success) return result;
        error.addIssue({
            code: z.ZodIssueCode.custom,
            message: result.error.message,
            path: [i],
        });
    }
    return { success: false, error };
};

export type LabelState<R extends TypedAttributeMap> = {
    label: TypedLabel<R>;
    state: ObjectState;
    values: TypedAttributeValues<R>;
};

export const getLabelStates = <R extends TypedAttributeMap>(
    states: ObjectState[],
    label: TypedLabel<R>,
): LabelState<R>[] => {
    // Get all annotations for this label
    const matchingStates = states.filter((s) => s.label.id === label.id);
    const values: LabelState<R>[] = [];
    for (const state of matchingStates) {
        const labelState: any = { label, state, values: {} };
        // For each typed attribute of the label, get the value from the annotation
        for (const [name, { id, schema }] of Object.entries(label.types)) {
            labelState.values[name] = undefined;
            const rawObject = parseJSONObjectOrNull(state.attributes[id]);
            if (rawObject) {
                const parsed = schema.safeParse(rawObject);
                if (parsed.success) {
                    labelState.values[name] = parsed.data;
                } else {
                    console.debug('Failed to parse attribute', name, parsed.error);
                }
            }
        }
        values.push(labelState);
    }
    return values;
};