import { CanvasPlugin } from 'cvat-canvas-wrapper';
import { ObjectState } from 'cvat-core-wrapper';
import { parseTypedAttributeName } from './model';
import { ALL_TYPED_ATTRIBUTES } from './model/all-typed-attributes';

export class CanvasPluginImpl implements CanvasPlugin {
    formatAttribute(state: ObjectState, attrId: string): { name: string; values: string[] } | undefined {
        const id = Number(attrId);
        const attributeDefinition = state.label.attributes.find((a) => a.id === id);
        if (!attributeDefinition) return;
        const typedName = parseTypedAttributeName(attributeDefinition.name);
        if (!typedName) return;
        const typedAttribute = ALL_TYPED_ATTRIBUTES[typedName.type];
        const value = typedAttribute?.parseValueObjectOrNull(state.attributes[id]);
        if (!typedAttribute || !value) return;
        const formatted = typedAttribute.format(value);
        return formatted ? { name: typedName.name, values: [formatted] } : undefined;
    }
}
