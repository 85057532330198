import { useEffect } from 'react';
import { GeolocationTask } from '../geolocation-task';
import { createUseTask } from './create-use-task';

const useGeolocationTaskBase = createUseTask(GeolocationTask);

export const useGeolocationTask = () => {
    const task = useGeolocationTaskBase() as GeolocationTask;
    useDefaultAssetLocations(task);
    return task;
};

const useDefaultAssetLocations = (task: GeolocationTask) => {
    useEffect(() => {
        task.assets
            .filter((asset) => asset.location === undefined)
            .forEach((asset) => {
                asset.update(task.defaultLocation);
            });
    }, [task.assets, task.defaultLocation]);
};
